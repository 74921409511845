import React from 'react';
import PropTypes from 'prop-types';

import MiniOverlayModal from 'dpl/common/mini_overlay_modal/components/MiniOverlayModal';
import Icon from 'dpl/common/components/Icon';
import { HELP_EMAIL } from 'dpl/shared/constants/breeder_app';

export default function Modal({
  className,
  children,
  title,
  onCloseClick,
  displayHelp,
  hideCloseButton
}) {
  return (
    <MiniOverlayModal
      className={className}
      onCloseClick={onCloseClick}
      hideCloseButton={hideCloseButton}
    >
      <div className={className}>
        <div className="ph20-md ph8 pt8 pb10 tc">
          <div className="dib mb5 pv10 ph10 relative br-100 bg-lightest-purple">
            <Icon
              className="purple center mb6 transform-center-all"
              height="40px"
              name="shield-locked"
              width="40px"
            />
          </div>
          <h5 className="mb6 f5">{title}</h5>
          {children}
          {displayHelp && (
            <p className="mb8">
              Having trouble accessing your account?&nbsp;
              <a className="underline" href={`mailto:${HELP_EMAIL}`}>
                Contact our team
              </a>
            </p>
          )}
        </div>
      </div>
    </MiniOverlayModal>
  );
}

Modal.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  displayHelp: PropTypes.bool,
  hideCloseButton: PropTypes.bool
};

Modal.defaultProps = {
  displayHelp: false,
  hideCloseButton: false
};

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { resetParams } from 'dpl/actions/query_params';
import {
  VERIFY_DEVICE_ERROR,
  USER_DEVICE_VERIFICATION_ID
} from 'dpl/constants/query_params';
import { VERIFY_NEW_DEVICE_PATH } from 'dpl/shared/constants/urls';

import Modal from './Modal';

export default function DeviceVerificationErrorModal() {
  const isDeviceVerificationErrorModalOpen = useSelector(
    state => state.queryParams[VERIFY_DEVICE_ERROR]
  );
  const userId = useSelector(
    state => state.queryParams[USER_DEVICE_VERIFICATION_ID]
  );
  const dispatch = useDispatch();
  function onCloseClick() {
    return dispatch(resetParams(VERIFY_DEVICE_ERROR));
  }

  if (!isDeviceVerificationErrorModalOpen) {
    return null;
  }

  return (
    <Modal
      className="DeviceVerificationErrorModal"
      title="Oops! There’s an error with your link. "
      onCloseClick={onCloseClick}
      hideCloseButton
    >
      <div className="mb6">
        <p className="mb15">
          Your verification link was either entered incorrectly or has expired.
          Try the link again or get a new link emailed to you, below.
        </p>

        <div className="row">
          <div className="col-8">
            <a
              className="button button--primary"
              href={`${VERIFY_NEW_DEVICE_PATH}?u=${encodeURIComponent(userId)}`}
            >
              Get a new link
            </a>
          </div>
          <div className="col-4">
            <button
              type="button"
              className="button button--secondary w-100 lh-0 pv5"
              onClick={onCloseClick}
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

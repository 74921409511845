// Dumb file name, but this is where we put common, non-page-specific logic

import initReactApp from 'dpl/util/initReactApp';
import ToastNotificationsListContainer from 'dpl/containers/ToastNotificationsListContainer';
import UserAuthModalContainer from 'dpl/containers/UserAuthModalContainer';
import InternationalBreederModal from 'dpl/components/InternationalBreederModal';
import DeviceVerificationModal from 'dpl/device_verification/components/DeviceVerificationModal';
import DeviceVerificationErrorModal from 'dpl/device_verification/components/DeviceVerificationErrorModal';
import DeviceVerificationSuccessModal from 'dpl/device_verification/components/DeviceVerificationSuccessModal';
import EmailConfirmationModal from 'dpl/email_confirmation/components/EmailConfirmationModal';
import CompactTrustSection from 'dpl/common/components/CompactTrustSection';

initReactApp({
  ToastNotificationsListContainer,
  UserAuthModalContainer,
  InternationalBreederModal,
  DeviceVerificationModal,
  DeviceVerificationErrorModal,
  DeviceVerificationSuccessModal,
  EmailConfirmationModal,
  CompactTrustSection
});

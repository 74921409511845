import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { resetParams } from 'dpl/actions/query_params';
import { DEVICE_VERIFIED } from 'dpl/constants/query_params';

import Modal from './Modal';

export default function DeviceVerificationSuccessModal() {
  const isDeviceVerificationSuccessModalOpen = useSelector(
    state => state.queryParams[DEVICE_VERIFIED]
  );
  const dispatch = useDispatch();
  function onCloseClick() {
    return dispatch(resetParams(DEVICE_VERIFIED));
  }

  if (!isDeviceVerificationSuccessModalOpen) {
    return null;
  }

  return (
    <Modal
      className="DeviceVerificationSuccessModal"
      title="Your device has been verified"
      onCloseClick={onCloseClick}
    >
      <p className="mb6">
        You will not need to verify this device on future logins.
      </p>
    </Modal>
  );
}

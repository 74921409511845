import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { resetParams } from 'dpl/actions/query_params';
import { VERIFY_DEVICE } from 'dpl/constants/query_params';
import { get } from 'dpl/shared/utils/object';
import useCurrentUser from 'dpl/common/hooks/useCurrentUser';

import Modal from './Modal';

export default function DeviceVerificationModal() {
  const currentUser = useCurrentUser();
  const isDeviceVerificationModalOpen = useSelector(
    state => state.queryParams[VERIFY_DEVICE]
  );
  const dispatch = useDispatch();
  function onCloseClick() {
    return dispatch(resetParams(VERIFY_DEVICE));
  }

  if (!isDeviceVerificationModalOpen) {
    return null;
  }

  const userEmail = get(currentUser, 'value.data.email', '');

  return (
    <Modal
      className="DeviceVerificationModal"
      title="Check your email to verify your log in"
      onCloseClick={onCloseClick}
      displayHelp
    >
      <p className="mb6">
        This looks like a new device or log in. To be extra safe and protect our
        breeders&apos; accounts, please go to your email&nbsp;
        <span>{`(${userEmail})`}</span>
        <span>
          &nbsp;to verify your log in. Please click the link in the email to
          proceed.
        </span>
      </p>
    </Modal>
  );
}
